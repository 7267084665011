import React, { Component } from "react";
import { withCookies, Cookies } from 'react-cookie';
import { instanceOf } from 'prop-types';
import axios from 'axios';

// Components
import Menu from '@components/Menu'

/* Import Components */
import Input from "@components/Input";
import TextArea from "@components/TextArea";
import Button from "@components/Button";
import Checkbox from "@components/CheckBox";
import AutoComplete from "@components/AutoComplete";
import { MdClear, MdAddCircleOutline, MdVisibility } from "react-icons/md";
import Switch from "@components/Switch";
import Modal from '@components/Modal';

import { FaEdit, FaTrash } from "react-icons/fa";

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import "react-tabs/style/react-tabs.css";

import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';

import ReactTable from "@components/ReactTable";

import { threeDecimal, valueWithIva } from '@utils/format.js'

import { TimestampToDate } from '@utils/date'

import UserContext from '@components/context/userContext';

import { EventBus as eventBus } from "@components/EventBus";

const extraZero = (value) => (value < 10 ? `0${value}` : value);

const TimestampToDatetime = (timestamp) => {
    if (!timestamp) {
        return '';
    }
    const datum = new Date(timestamp * 1000);
    const year = datum.getFullYear();
    const month = extraZero(datum.getMonth() + 1);
    const day = extraZero(datum.getDate());
    return `${year}-${month}-${day}`;
  };



class Form extends Component {
    static contextType = UserContext;

    static propTypes = {
        cookies: instanceOf(Cookies).isRequired
    };

  constructor(props) {
    super(props);

    const { match: { params }, type } = this.props;

    const searchHousehold = "";

    this.state = {
        loaded: false,
        nome_1: '',
        nome_2: '',
        email: '',
        nif: '',
        telefone: '',
        telefone_2: '',
        fax: '',
        telemovel: '',
        morada_1: '',
        morada_2: '',
        codigo_postal: '',
        credito_concedido: 0.00,
        plafond: 0.00,
        codigo: type === 'edit' && params.codigo,
        edit: type === 'edit',
        dataChanged: -1,
        tableDescontos: {
            columns: [
                {
                    Header: "Grupo",
                    accessor: "grupo",
                },
              {
                Header: "Familia",
                accessor: "familia",
              },
              {
                Header: "Produto",
                accessor: "produto",
              },
              {
                Header: "Dia Semana",
                accessor: "dia_semana",
              },
              {
                Header: "Hora Inicial",
                accessor: "hora_inicial",
              },
              {
                Header: "Hora Final",
                accessor: "hora_final",
              },
              {
                Header: "Desconto %",
                accessor: "desconto_%",
              },
              {
                Header: "Desconto Valor",
                accessor: "desconto_valor",
              },
              {
                Header: "Preço Unitário",
                accessor: "preco_unitario",
              },
              {
                Header: "Produto Parceiro",
                accessor: "produto_parceiro",
              },
              {
                Header: "",
                accessor: "delete",
              },
            ],
            rows: [],
            loading: true,
        },
        tableMatriculas: {
            columns: [
                {
                    Header: "Codigo",
                    accessor: "codigo",
                },
              {
                Header: "Matricula",
                accessor: "matricula",
              },
              {
                Header: "Codigo Tipo Viatura",
                accessor: "codigo_tipo_viatura",
              },
              {
                Header: "Codigo Marca",
                accessor: "codigo_marca",
              },
              {
                Header: "Modelo",
                accessor: "modelo",
              },
              {
                Header: "Codigo Tipo Combustivel",
                accessor: "codigo_tipo_combustivel",
              },
              {
                Header: "Cilindrada",
                accessor: "Cilindrada",
              },
              {
                Header: "Ano Matricula",
                accessor: "ano_matricula",
              },
              {
                Header: "Chassis",
                accessor: "chassis",
              },
              {
                Header: "PS",
                accessor: "ps",
              },
              {
                Header: "Bloqueada",
                id: "bloqueada",
                accessor: d => d.bloqueada === 'True' ? (
                    <span className="badge badge-danger">Sim</span>
                  ) : (
                    <span className="badge badge-success">Nao</span>
                  ),
              },
              {
                Header: "Pergunta Kilometros",
                id: "pergunta_kilometros",
                accessor: d => d.pergunta_kilometros === 'True' ? (
                    <span className="badge badge-success">Sim</span>
                  ) : (
                    <span className="badge badge-danger">Nao</span>
                  ),
              },
              {
                Header: "Kms",
                accessor: "kms",
              },
              {
                Header: "Pergunta Requisição",
                id: "pergunta_requisicao",
                accessor: d => d.pergunta_requisicao === 'True' ? (
                    <span className="badge badge-success">Sim</span>
                  ) : (
                    <span className="badge badge-danger">Nao</span>
                  ),
              },
              {
                Header: "Acumula Pontos",
                id: "acumula_pontos",
                accessor: d => d.acumula_pontos === 'True' ? (
                    <span className="badge badge-success">Sim</span>
                  ) : (
                    <span className="badge badge-danger">Nao</span>
                  ),
              },
              {
                Header: "Obs Consola Mat",
                accessor: "obs_consola_mat",
              },
              {
                Header: "Sub Conta",
                accessor: "sub_conta",
              },
              {
                Header: "Colectivo",
                accessor: "colectivo",
              },
              {
                Header: "Grupo Mat",
                accessor: "grupo_mat",
              },
              {
                Header: "",
                accessor: "delete",
              },
            ],
            rows: [],
            loading: true,
        },
        newgrupo: '',
        newfamilia: { value: '', label: 'Nenhuma' },
        newproduto: { value: '', label: 'Nenhum' },
        newdiasemana: '',
        newhorainicial: '',
        newhorafinal: '',
        newdescontoper: '',
        newdescontovalor: '',
        newprecounitario: '',
        newprodutoparceiro: '',


        newEdit: false,
        newEditCodigo: '',
        newcodigo: '',
        newmatricula: '',
        newcodigo_tipo_viatura: '',
        newcodigo_marca: '',
        newmodelo: '',
        newcodigo_tipo_combustivel: '',
        newcilindrada: '',
        newano_matricula: '',
        newchassis: '',
        newps: '',
        newbloqueada: false,
        newpergunta_kilometros: false,
        newkms: '',
        newpergunta_requisicao: false,
        newacumula_pontos: false,
        newobs_consola_mat: '',
        newsub_conta: '',
        newcolectivo: '',
        newgrupo_mat: '',





        categories: [],
        products: [],
        // services: [],
        // kinships: [],
        // healthSystems: [],
        // showPopup: {},
        // tableHousehold: {
        //     columns: [
        //       {
        //         Header: "Nome",
        //         accessor: "name",
        //       },
        //       {
        //         Header: "Parentesco",
        //         accessor: "kinship",
        //       },
        //       {
        //         Header: "Telefone",
        //         accessor: "phone",
        //       },
        //       {
        //         Header: "Email",
        //         accessor: "email",
        //       },
        //       {
        //         Header: "Aniversário",
        //         accessor: "birthday",
        //       },
        //       {
        //         Header: "GDPR",
        //         accessor: "gdpr",
        //       },
        //       {
        //         Header: "",
        //         accessor: "view",
        //       },
        //     ],
        //     rows: [],
        //     loading: true,
        // },
        // tableQuotas: {
        //     columns: [
        //         {
        //             Header: "Data Pagamento",
        //             accessor: "date",
        //         },
        //       {
        //         Header: "Ano",
        //         accessor: "year",
        //       },
        //       {
        //         Header: "Mês",
        //         accessor: "month",
        //       },
        //       {
        //         Header: "Valor Quota",
        //         accessor: "value",
        //       },
        //       {
        //         Header: "recbio nr.",
        //         accessor: "invoice",
        //       },
        //       {
        //         Header: "",
        //         accessor: "view",
        //       },
        //     ],
        //     rows: [],
        //     loading: true,
        // },
    };

    //this.handleEmailSend = this.handleEmailSend.bind(this);
    //this.handleTableSubmit = this.handleTableSubmit.bind(this);
    this.handleComissionUpdate = this.handleComissionUpdate.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
    this.handleFormCancel = this.handleFormCancel.bind(this);
    this.loadFunction = this.loadFunction.bind(this);
    this.showPopupHandler = this.showPopupHandler.bind(this);
    this.hidePopupHandler = this.hidePopupHandler.bind(this);
    this.showCameraHandler = this.showCameraHandler.bind(this);
    this.showUploadHandler = this.showUploadHandler.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.removeFunction = this.removeFunction.bind(this);
    this.handleDescontosSubmitSuccess = this.handleDescontosSubmitSuccess.bind(this);
    this.handleDescontoDeleteSuccess = this.handleDescontoDeleteSuccess.bind(this);
    this.handleMatriculasSubmitSuccess = this.handleMatriculasSubmitSuccess.bind(this);
    this.handleMatriculaDeleteSuccess = this.handleMatriculaDeleteSuccess.bind(this);
    this.editMatricula = this.editMatricula.bind(this);
  }

loadFunction() {
    const profile = this.context;
    const { history } = this.props;

    if (!this.state.loaded) {
        if (this.props.type === 'edit') {
            axios.get(`https://eposgestofinal.pt/api/products/get.php?idempresa=${profile.idEmpresa}`)
                .then(response => {
                    const products = response.data !== 'NOK' ? response.data : [];
        
                    this.setState({ products: [{ value: '', label: 'Nenhum' }, ...products.map((product) => ({ ...product, value: product.idproduct, label: product.nome }))], loaded: true });
                })
                .catch(error => {
                    console.error(error);
                });

            axios
                .get(
                `https://eposgestofinal.pt/api/categories/get.php?idempresa=${profile.idEmpresa}`
                )
                .then((response) => {
                const categories = response.data !== "NOK" ? response.data : [];

                this.setState({
                    categories: [{ value: '', label: 'Nenhuma' },
                    ...categories.map((category) => ({
                        value: category.idcategoria,
                        label: `[${category.idcategoria}] ${category.descricaocategoria}`,
                    })),
                    ],
                    loaded: true,
                });
                })
                .catch((error) => {
                console.error(error);
                });

            axios.get(`https://eposgestofinal.pt/api/clientes/getById.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
            .then(response => {
                const customer = response.data !== 'NOK' ? response.data[0] : {};

                this.setState({
                    ...customer,
                    ativo: !(customer.inactivo === 't'),
                    // healthSystem: this.state.healthSystems.find((healthSystem) => healthSystem.value === customers.health_system) || {
                    //     label: 'Nenhum',
                    //     value: undefined,
                    // },
                    // quota: this.state.quotaTypes.find((quotatype) => quotatype.value === parseInt((customers.quota || 0), 10)),
                    // gdpr: customers.gdpr === 't',
                    loaded: true });
            })
            .catch(error => {
                console.error(error);
            });

            axios.get(`https://eposgestofinal.pt/api/clientes/descontos/getById.php?idempresa=${profile.idEmpresa}&cliente=${this.state.codigo}`)
            .then(response => {
                const descontos = response.data !== "NOK" ? response.data : [];

                const enrichedDescontos = descontos.map((desconto) => ({
                    ...desconto,
                    delete: (
                      <MdClear
                        style={{ fontSize: "15px", cursor: "pointer" }}
                        onClick={() =>
                            this.handleDescontoDeleteSuccess(desconto.grupo, desconto.familia, desconto.produto)
                        }
                      />
                    ),
                  }));

                this.setState({
                    tableDescontos: {
                        ...this.state.tableDescontos,
                        rows: enrichedDescontos,
                        loading: false,
                    },
                    loaded: true,
                });
            })
            .catch(error => {
                console.error(error);
            });

            axios.get(`https://eposgestofinal.pt/api/clientes/matriculas/getById.php?idempresa=${profile.idEmpresa}&cliente=${this.state.codigo}`)
            .then(response => {
                const matriculas = response.data !== "NOK" ? response.data : [];

                const enrichedMatriculas = matriculas.map((matricula) => ({
                    ...matricula,
                    delete: (
                      <table className="actionButtons" style={{ width: '100%', fontSize: 'xx-small' }}>
                        <tr style={{ lineHeight: '10px' }}>
                        <td
                            style={{ cursor: "pointer" }}
                            onClick={() => this.handleMatriculaDeleteSuccess(matricula.codigo)}
                        >
                            <FaTrash/>
                            <p>apagar</p>
                        </td>
                        <td
                            style={{ fontWeight: 'bolder', fontSize: 'x-small', cursor: "pointer" }}
                            onClick={() => this.editMatricula(matricula.codigo)}
                        >
                            <FaEdit/>
                            <p>editar</p>
                        </td>
                        </tr>
                    </table>
                    ),
                  }));

                this.setState({
                    tableMatriculas: {
                        ...this.state.tableMatriculas,
                        rows: enrichedMatriculas,
                        loading: false,
                    },
                    loaded: true,
                });
            })
            .catch(error => {
                console.error(error);
            });
            
            // axios.get(`https://eposgestofinal.pt/api/clientes/services/get.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
            // .then(response => {
            //     const services = response.data !== 'NOK' ? response.data : [];

            //     this.setState({ services, loaded: true });
            // })
            // .catch(error => {
            //     console.error(error);
            // });
            // this.setState({ loaded: true, dataChanged: 0 });

            // axios.get(`https://eposgestofinal.pt/api/clientes/household/get.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
            // .then(response => {
            //     const households = response.data !== "NOK" ? response.data : [];

            //     console.log(this.state.kinships, households);

            //     const enrichedHouseholds = households.map((household) => ({
            //         ...household,
            //         kinship: this.state.kinships.find((kinship) => kinship.value === household.kinship) ? this.state.kinships.find((kinship) => kinship.value === household.kinship).label : 'Não definido',
            //         gdpr: household.gdpr === "t" ? "TRUE" : "FALSE",
            //         view: (
            //         <MdVisibility
            //             style={{ fontSize: "15px", cursor: "pointer" }}
            //             onClick={() =>
            //             history.push(`/clientes/${this.state.codigo}/household/${household.idhousehold}`)
            //             }
            //         />
            //         ),
            //     }));

            //     this.setState({
            //         tableHousehold: {
            //             ...this.state.tableHousehold,
            //             rows: enrichedHouseholds,
            //             loading: false,
            //         },
            //         loaded: true,
            //     });
            // })
            // .catch(error => {
            //     console.error(error);
            // });

            // axios.get(`https://eposgestofinal.pt/api/clientes/quotas/get.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
            // .then(response => {
            //     const quotas = response.data !== "NOK" ? response.data : [];

            //     const enrichedQuotas = quotas.map((quota) => ({
            //         ...quota,
            //         date: TimestampToDatetime(quota.date),
            //         view: (
            //         <MdVisibility
            //             style={{ fontSize: "15px", cursor: "pointer" }}
            //             onClick={() =>
            //             history.push(`/clientes/${this.state.codigo}/quotas/${quota.idquota}`)
            //             }
            //         />
            //         ),
            //     }));

            //     this.setState({
            //         tableQuotas: {
            //             ...this.state.tableQuotas,
            //             rows: enrichedQuotas,
            //             loading: false,
            //         },
            //         loaded: true,
            //     });
            // })
            // .catch(error => {
            //     console.error(error);
            // });
        } else {
            this.setState({ loaded: true });
        }
    }
  }

  handleComissionUpdate = (idproduct, comissionInfo) => {
    const { data } = this.state;
    const newData = data.map(row => {
      if (row.idproduct === idproduct) {
        const comission = comissionInfo.comission || row.comission;
        return {
          ...row,
          ...comissionInfo,
          finalprice: threeDecimal(parseFloat(row.costprice || 0) + parseFloat(comission || 0)),
          finalprice_iva: threeDecimal(valueWithIva(parseFloat(row.costprice || 0) + parseFloat(comission || 0), row.iva)),
        };
      }
      return row;
    });
    this.setState({ data: newData, dataChanged: 1 });
  }

  handleChange(e, callback) {
    let value = e.target.value;
    let name = e.target.name;
    
    this.setState({
        [name]: value,
    }, () => {
        if (name === 'quota') {
            this.handleFormSubmit(null, true);
        }
    });
  }

  handleCheckboxChange(e, callback) {
    let value = e.target.checked;
    let name = e.target.name;

    this.setState({
        [name]: value,
    });
  };

  editMatricula(codigo) {
    const profile = this.context;
    axios.get(`https://eposgestofinal.pt/api/clientes/matriculas/getById.php?idempresa=${profile.idEmpresa}&cliente=${this.state.codigo}&codigo=${codigo}`)
        .then(response => {
            const matricula = response.data !== "NOK" ? response.data[0] : {};

            this.setState({
                newEdit: true,
                newcodigo: matricula.codigo,
                newmatricula: matricula.matricula,
                newcodigo_tipo_viatura: matricula.codigo_tipo_viatura,
                newcodigo_marca: matricula.codigo_marca,
                newmodelo: matricula.modelo,
                newcodigo_tipo_combustivel: matricula.codigo_tipo_combustivel,
                newcilindrada: matricula.cilindrada,
                newano_matricula: matricula.ano_matricula,
                newchassis: matricula.chassis,
                newps: matricula.ps,
                newbloqueada: matricula.bloqueada === 'True',
                newpergunta_kilometros: matricula.pergunta_kilometros === 'True',
                newkms: matricula.kms,
                newpergunta_requisicao: matricula.pergunta_requisicao === 'True',
                newacumula_pontos: matricula.acumula_pontos === 'True',
                newobs_consola_mat: matricula.obs_consola_mat,
                newsub_conta: matricula.sub_conta,
                newcolectivo: matricula.colectivo,
                newgrupo_mat: matricula.grupo_mat,
            }, () => eventBus.$emit("Modal-matriculas", { header: "Editar matricula", body: null }));
        })
        .catch(error => {
            console.error(error);
        });
  }

//   handleTableSubmit(e) {
//     const profile = this.context;
//     e.preventDefault();
    
//     axios.post('https://eposgestofinal.pt/api/clientes/services/set.php', {
//         services: this.state.data.filter((product) => product.comission),
//         idempresa: profile.idEmpresa,
//         codigo: this.state.codigo,
//     })
//     .then(response => {
//         this.setState({ dataChanged: 0 });
//     })
//     .catch(error => {
//         console.error(error);
//     });
//   }

//   handleEmailSend(e) {
//     const profile = this.context;

//     e.preventDefault();
//     confirmAlert({
//         title: 'Confirmação',
//         message: `Tem a certeza que deseja enviar estes dados por email para o cliente ${this.state.name} <${this.state.email}>?`,
//         buttons: [
//             {
//                 label: 'Sim',
//                 onClick: () => {
//                     const { history } = this.props;
                    
//                     axios.get(`https://eposgestofinal.pt/api/clientes/services/sendEmail.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}`)
//                     .then(response => {
//                         if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
//                             window.close();
//                         } else {
//                             history.push('/clientes');
//                         }
//                     })
//                     .catch(error => {
//                         console.error(error);
//                     });
//                 }
//             },
//             {
//                 label: 'Não',
//                 onClick: () => {}
//             }
//         ]
//     });
//   }

  handleFormSubmit(e, quota) {
    const profile = this.context;
    const { history } = this.props;
    if (e) {
        e.preventDefault();
    }

    const validacp = /^\d{4}(-\d{3})?$/.test(this.state.codigo_postal);

    if (!validacp) {
      alert("Código Postal inválido! Apenas formato xxxx-xxx ou xxxx permitidos.")
      return;
    }
    
    axios.post('https://eposgestofinal.pt/api/clientes/set.php', {
        idempresa: profile.idEmpresa,
        codigo: this.state.codigo,
        nome_1: this.state.nome_1,
        nome_2: this.state.nome_2,
        nif: this.state.nif,
        email: this.state.email,
        telefone: this.state.telefone,
        telefone_2: this.state.telefone_2,
        fax: this.state.fax,
        telemovel: this.state.telemovel,
        morada_1: this.state.morada_1,
        morada_2: this.state.morada_2,
        codigo_postal: this.state.codigo_postal,
        credito_concedido: this.state.credito_concedido,
        ativo: this.state.ativo ? 't' : 'f',
    })
    .then(response => {
        if (quota) {
            return;
        }
        if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
            window.close();
        } else {
            history.push('/clientes');
        }
    })
    .catch(error => {
        console.error(error);
    });
  }

  handleFormCancel() {
    const { history } = this.props;
    if (this.props.location && this.props.location.query && this.props.location.query.closeAfter) {
        window.close();
    } else {
        history.push('/clientes');
    }
  }

  showPopupHandler(idempresa, codigo, idservice) {
    this.setState({ showPopup: { idempresa, codigo, idservice, photo: false, upload: false } });
  }

  hidePopupHandler() {
    this.setState({ showPopup: {} });
  }

  showCameraHandler() {
    this.setState({ showPopup: { ...this.state.showPopup, photo: true, upload: false } });
  }

  showUploadHandler() {
    this.setState({ showPopup: { ...this.state.showPopup, photo: false, upload: true } });
  }

  filterData(searchTerm) {
    this.setState({ searchHousehold: searchTerm });
  }

  async removeFunction(idservice) {
    const profile = this.context;

    confirmAlert({
        title: 'Confirmação',
        message: `Tem a certeza que deseja apagar o serviço?`,
        buttons: [
            {
                label: 'Sim',
                onClick: () => {
                    axios.get(`https://eposgestofinal.pt/api/clientes/services/unset.php?idempresa=${profile.idEmpresa}&codigo=${this.state.codigo}&idservice=${idservice}`)
                    .then(response => {
                        if (response.data !== 'NOK') {
                            this.setState({ services: this.state.services.filter(service => service.idservice !== idservice) });
                        }
                    })
                    .catch(error => {
                        console.error(error);
                    });
                }
            },
            {
                label: 'Não',
                onClick: () => {}
            }
        ]
    });
}

handleDescontosSubmitSuccess() {
    const profile = this.context;
    if (this.state.codigo) {
      axios
        .post("https://eposgestofinal.pt/api/clientes/descontos/set.php", {
          idempresa: profile.idEmpresa,
          cliente: this.state.codigo,
          grupo: this.state.newgrupo,
          familia: this.state.newfamilia.value,
          produto: this.state.newproduto.value,
          dia_semana: this.state.newdiasemana,
          hora_inicial: this.state.newhorainicial,
          hora_final: this.state.newhorafinal,
          desconto_per: this.state.newdescontoper,
          desconto_valor: this.state.newdescontovalor,
          preco_unitario: this.state.newprecounitario,
          produto_parceiro: this.state.newprodutoparceiro,
        })
        .then((response) => {
          eventBus.$emit("Modal-descontos", { isOpen: false });
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleDescontoDeleteSuccess(grupo, familia, produto) {
    const profile = this.context;
    if (this.state.codigo) {
      axios
        .post("https://eposgestofinal.pt/api/clientes/descontos/unset.php", {
          idempresa: profile.idEmpresa,
          cliente: this.state.codigo,
          grupo: grupo,
          familia: familia,
          produto: produto,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  handleMatriculasSubmitSuccess() {
    const profile = this.context;
    if (this.state.newcodigo && !isNaN(this.state.newcodigo) && this.state.newcodigo.length > 0 && this.state.newcodigo.length <= 9) {
        axios.get(`https://eposgestofinal.pt/api/clientes/matriculas/getById.php?idempresa=${profile.idEmpresa}&codigo=${this.state.newcodigo}`)
            .then(response => {
                const uniqCodigo = response.data !== 'NOK' && !response.data.length;
                if (this.state.newEdit || uniqCodigo) {
                    axios
                      .post("https://eposgestofinal.pt/api/clientes/matriculas/set.php", {
                          idempresa: profile.idEmpresa,
                          cliente: this.state.codigo,
                          codigo: this.state.newcodigo,
                          matricula: this.state.newmatricula,
                          codigo_tipo_viatura: this.state.newcodigo_tipo_viatura,
                          codigo_marca: this.state.newcodigo_marca,
                          modelo: this.state.newmodelo,
                          codigo_tipo_combustivel: this.state.newcodigo_tipo_combustivel,
                          cilindrada: this.state.newcilindrada,
                          ano_matricula: this.state.newano_matricula,
                          chassis: this.state.newchassis,
                          ps: this.state.newps,
                          bloqueada: this.state.newbloqueada ? 'True' : 'False',
                          pergunta_kilometros: this.state.newpergunta_kilometros ? 'True' : 'False',
                          kms: this.state.newkms,
                          pergunta_requisicao: this.state.newpergunta_requisicao ? 'True' : 'False',
                          acumula_pontos: this.state.newacumula_pontos ? 'True' : 'False',
                          obs_consola_mat: this.state.newobs_consola_mat,
                          sub_conta: this.state.newsub_conta,
                          colectivo: this.state.newcolectivo,
                          grupo_mat: this.state.newgrupo_mat,
                      })
                      .then((response) => {
                        eventBus.$emit("Modal-matriculas", { isOpen: false });
                        window.location.reload();
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                    } else {
                        alert('Código já existente!')
                    }
            })
            .catch(error => {
                console.error(error);
            });
    } else {
        alert('Código inválido! Tem de ser um número com no máximo 9 carcteres.')
    }
  }

  handleMatriculaDeleteSuccess(codigo) {
    const profile = this.context;
    if (this.state.codigo) {
      axios
        .post("https://eposgestofinal.pt/api/clientes/matriculas/unset.php", {
          idempresa: profile.idEmpresa,
          cliente: this.state.codigo,
          codigo: codigo,
        })
        .then((response) => {
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }

  render() {
    const { location, history } = this.props;
    const {
        edit,
        codigo,
        loaded,
        nome_1,
        nome_2,
        email,
        telefone,
        telefone_2,
        fax,
        telemovel,
        nif,
        morada_1,
        morada_2,
        codigo_postal,
        credito_concedido,
        plafond,
        dataChanged,
        ativo,
        tableDescontos,
        newgrupo,
        newfamilia,
        newproduto,
        newdiasemana,
        newhorainicial,
        newhorafinal,
        newdescontoper,
        newdescontovalor,
        newprecounitario,
        newprodutoparceiro,
        tableMatriculas,
        newEdit,
        newcodigo,
        newmatricula,
        newcodigo_tipo_viatura,
        newcodigo_marca,
        newmodelo,
        newcodigo_tipo_combustivel,
        newcilindrada,
        newano_matricula,
        newchassis,
        newps,
        newbloqueada,
        newpergunta_kilometros,
        newkms,
        newpergunta_requisicao,
        newacumula_pontos,
        newobs_consola_mat,
        newsub_conta,
        newcolectivo,
        newgrupo_mat,
        categories,
        products,
    } = this.state;
    const cookies = new URLSearchParams(location.search);
    const profile = this.context;

    // const filterSearchHousehold = searchHousehold.split(" ");

    // const tableHouseholdRows = tableHousehold.rows.length
    //   ? tableHousehold.rows.filter((customer) =>
    //     filterSearchHousehold.every(
    //         (eachSearch) =>
    //           eachSearch === "" ||
    //           (customer.name && customer.name.toLowerCase().includes(eachSearch.toLowerCase())) ||
    //           (customer.idagregado	 && customer.idagregado	.toLowerCase().includes(eachSearch.toLowerCase()))
    //       )
    //     )
    //   : [];

    return (
        <div>
            <Menu loadFunction={ this.loadFunction() } location={ location } history={ history } newCookies={ cookies } />
            <div className="fullContainer" style={{ maxWidth: '100%' }}>
                <div className="col-xs-12">
                    <ol
                        style={{
                            textAlign: 'left',
                            'marginTop': '75px',
                        }}
                        className="breadcrumb"
                    >
                        <li><a href="http://eposgestofinal.pt/index.php">Portal</a></li>
                        <li><a href="/clientes">Clientes</a></li>
                        <li className="active">
                            {edit ? (<span>{codigo}</span   >) : (<span>Novo Cliente</span>)}
                        </li>
                    </ol>
                </div>
            </div>
            <Tabs>
                <TabList>
                    <Tab>Detalhes</Tab>
                    <Tab>Descontos</Tab>
                    <Tab>Matriculas</Tab>
                    {/* {dataChanged !== -1 && (
                    <>
                        <Tab>Quotas</Tab>
                        <Tab>Agregado</Tab>
                        <Tab>Serviços</Tab>
                    </>
                    )} */}
                </TabList>

                <TabPanel>
                    {loaded && (
                    <div
                        className="fullContainer" style={{ maxWidth: '100%' }}
                    >
                        <div
                            className="col-sm-12"
                            style={{ textAlign: 'center' }}
                        >
                            <div className="panel panel-default">
                                <div
                                    className="panel-heading"
                                    style={{
                                        fontWeight: 'bold',
                                        fontSize: '15pt',
                                        display: 'flex',
                                        justifyContent: 'center',
                                        alignItems: 'center',
                                    }}
                                >
                                    <div
                                        style={{
                                            width: '100%',
                                            textAlign: 'left',
                                        }}
                                    >
                                        Detalhes
                                    </div>
                                </div>
                                <div
                                    style={{
                                        textAlign: 'left',
                                        width: '100%',
                                    }}
                                    className="container-fluid"
                                >
                                    <form class="well form-horizontal" action=" " method="post" id="contact_form">
                                        <div class="st st-theme-github st-horizontal">
                                            <fieldset>
                                            <div className="form-group">
                                                    <label className="col-md-1 control-label">Nome 1</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-user"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"nome_1"}
                                                                value={nome_1}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Nome 2</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-user"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"nome_2"}
                                                                value={nome_2}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">NIF</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-id-card"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"nif"}
                                                                value={nif}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">E-Mail</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-envelope"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"email"}
                                                                value={email}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Telefone</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-phone"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"telefone"}
                                                                value={telefone}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Telefone 2</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-phone"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"telefone_2"}
                                                                value={telefone_2}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Fax</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-phone"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"fax"}
                                                                value={fax}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Telemovel</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-phone"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"telemovel"}
                                                                value={telemovel}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Morada 1</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-home"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"morada_1"}
                                                                value={morada_1}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Código Postal</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-home"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"codigo_postal"}
                                                                value={codigo_postal}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Localidade</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-home"></i></span>
                                                            <Input
                                                                inputType={"text"}
                                                                name={"morada_2"}
                                                                value={morada_2}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Crédito Concebido</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-euro-sign"></i></span>
                                                            <Input
                                                                inputType={"number"}
                                                                step="0.01"
                                                                name={"credito_concedido"}
                                                                value={credito_concedido}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Plafond</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <span className="input-group-addon"><i className="fas fa-euro-sign"></i></span>
                                                            <Input
                                                                disabled
                                                                inputType={"number"}
                                                                step="0.01"
                                                                name={"plafond"}
                                                                value={plafond}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="form-group">
                                                    <label className="col-md-1 control-label">Ativo</label>  
                                                    <div className="col-md-11 inputGroupContainer">
                                                        <div className="input-group">
                                                            <Switch
                                                                name={"ativo"}
                                                                checked={ativo}
                                                                handleChange={this.handleChange}
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                    </form>
                                    {/* About you */}
                                    <Button
                                    action={this.handleFormSubmit}
                                    type={"primary"}
                                    title={"Submeter"}
                                    style={buttonStyle}
                                    />
                                    {/*Submit */}
                                    <Button
                                    action={this.handleFormCancel}
                                    type={"secondary"}
                                    title={"cancelar"}
                                    style={buttonStyle}
                                    />
                                    {/* Clear the form */}
                                </div>
                            </div>
                        </div>
                    </div>
                    )}
                </TabPanel>
                <TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Descontos{" "}
                              <h6>
                                {edit ? (
                                  <span>{nome_1}</span>
                                ) : (
                                  <span>Novo Cliente</span>
                                )}
                              </h6>
                            </div>
                            <div onClick={() => eventBus.$emit("Modal-descontos", { header: "Novo desconto", body: null })}>
                              <MdAddCircleOutline
                                style={{ fontSize: "20px", cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          {loaded && (
                            <div>
                                <ReactTable
                                    columns={tableDescontos.columns}
                                    sortable={true}
                                    loading={tableDescontos.loading}
                                    data={tableDescontos.rows}
                                />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                </TabPanel>
                <TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                      <div
                        className="col-sm-12"
                        style={{ textAlign: "center" }}
                      >
                        <div className="panel panel-default">
                          <div
                            className="panel-heading"
                            style={{
                              fontWeight: "bold",
                              fontSize: "15pt",
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                            }}
                          >
                            <div
                              style={{
                                width: "100%",
                                textAlign: "left",
                              }}
                            >
                              Matriculas{" "}
                              <h6>
                                {edit ? (
                                  <span>{nome_1}</span>
                                ) : (
                                  <span>Novo Cliente</span>
                                )}
                              </h6>
                            </div>
                            <div onClick={() => this.setState({
                                newEdit: false,
                                newEditCodigo: '',
                                newcodigo: '',
                                newmatricula: '',
                                newcodigo_tipo_viatura: '',
                                newcodigo_marca: '',
                                newmodelo: '',
                                newcodigo_tipo_combustivel: '',
                                newcilindrada: '',
                                newano_matricula: '',
                                newchassis: '',
                                newps: '',
                                newbloqueada: false,
                                newpergunta_kilometros: false,
                                newkms: '',
                                newpergunta_requisicao: false,
                                newacumula_pontos: false,
                                newobs_consola_mat: '',
                                newsub_conta: '',
                                newcolectivo: '',
                                newgrupo_mat: '',
                            }, () => eventBus.$emit("Modal-matriculas", { header: "Nova matricula", body: null }))}>
                              <MdAddCircleOutline
                                style={{ fontSize: "20px", cursor: "pointer" }}
                              />
                            </div>
                          </div>
                          {loaded && (
                            <div>
                                <ReactTable
                                    columns={tableMatriculas.columns}
                                    sortable={true}
                                    loading={tableMatriculas.loading}
                                    data={tableMatriculas.rows}
                                />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                </TabPanel>
                {/* {dataChanged !== -1 && (
                <TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                            <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Tipo de Quota
                                </div>
                            </div>
                            <AutoComplete
                                name={"quota"}
                                value={quota}
                                handleChange={this.handleChange}
                                options={quotaTypes}
                            />
                            </div>
                        </div>
                    </div>
                    {quota.value !== 0 && (<div className="fullContainer" style={{ maxWidth: "100%" }}>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                            <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Quotas
                                </div>
                                <div>
                                <table
                                    style={{ cursor: "pointer" }}
                                    onClick={() => history.push(`/clientes/${codigo}/quotas/new`)}
                                >
                                    <tr>
                                    <td>
                                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style={{ fontSize: "xx-small" }}>Adicionar quota</td>
                                    </tr>
                                </table>
                                </div>
                            </div>
                            <ReactTable
                                columns={tableQuotas.columns}
                                sortable={true}
                                loading={tableQuotas.loading}
                                data={tableQuotas.rows}
                            />
                            </div>
                        </div>
                    </div>)}
                </TabPanel>
                )}
                {dataChanged !== -1 && (
                <TabPanel>
                    <div className="fullContainer" style={{ maxWidth: "100%" }}>
                        <div className="col-sm-12" style={{ textAlign: "center" }}>
                            <div className="panel panel-default">
                            <div
                                className="panel-heading"
                                style={{
                                fontWeight: "bold",
                                fontSize: "15pt",
                                display: "flex",
                                justifyContent: "center",
                                alignItems: "center",
                                }}
                            >
                                <div
                                style={{
                                    width: "100%",
                                    textAlign: "left",
                                }}
                                >
                                Agregado
                                </div>
                                <div>
                                <table
                                    style={{ cursor: "pointer" }}
                                    onClick={() => history.push(`/clientes/${codigo}/household/new`)}
                                >
                                    <tr>
                                    <td>
                                        <MdAddCircleOutline style={{ fontSize: "20px" }} />
                                    </td>
                                    </tr>
                                    <tr>
                                    <td style={{ fontSize: "xx-small" }}>Adicionar membro</td>
                                    </tr>
                                </table>
                                </div>
                            </div>

                            <table className="table table-bordered table-hover table-sortable">
                                <thead>
                                <tr>
                                    <th className="text-center">Pesquisa</th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr>
                                    <td style={{ width: "250px" }}>
                                    <Input
                                        inputType={"text"}
                                        name={"search"}
                                        value={searchHousehold}
                                        placeholder="pesquisa..."
                                        handleChange={(e) => this.filterData(e.target.value)}
                                    />
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <ReactTable
                                columns={tableHousehold.columns}
                                sortable={true}
                                loading={tableHousehold.loading}
                                data={tableHouseholdRows}
                            />
                            </div>
                        </div>
                    </div>
                </TabPanel>
                )} */}
            </Tabs>
            <Modal id="descontos">
                <form class="well form-horizontal" action=" " method="post" id="contact_form">
                    <div class="st st-theme-github st-horizontal">
                        <fieldset>
                        <div className="form-group">
                                <label className="col-md-1 control-label">Groupo</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"number"}
                                            name={"newgrupo"}
                                            value={newgrupo}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Familia</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <AutoComplete
                                            name={"newfamilia"}
                                            value={newfamilia}
                                            handleChange={this.handleChange}
                                            options={categories}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Produto</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <AutoComplete
                                            name={"newproduto"}
                                            value={newproduto}
                                            handleChange={this.handleChange}
                                            options={products}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Dia Semana</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newdiasemana"}
                                            value={newdiasemana}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Hora Inicial</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newhorainicial"}
                                            value={newhorainicial}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Hora Final</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newhorafinal"}
                                            value={newhorafinal}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>

                            <div className="form-group">
                                <label className="col-md-1 control-label">Desconto %</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                inputType={"number"}
                                step="0.01"
                                name={"newdescontoper"}
                                value={newdescontoper}
                                handleChange={this.handleChange}
                            />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Desconto Valor</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"number"}
                                            step="0.01"
                                            name={"newdescontovalor"}
                                            value={newdescontovalor}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Preço Unitário</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            step="0.01"
                                            name={"newprecounitario"}
                                            value={newprecounitario}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Produto Parceiro</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newprodutoparceiro"}
                                            value={newprodutoparceiro}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <Button
                                action={this.handleDescontosSubmitSuccess}
                                type={"success"}
                                title={"Submeter"}
                                style={buttonStyle}
                            />
                        </fieldset>
                    </div>
                </form>
            </Modal>
            <Modal id="matriculas">
                <form class="well form-horizontal" action=" " method="post" id="contact_form">
                    <div class="st st-theme-github st-horizontal">
                        <fieldset>
                        <div className="form-group">
                                <label className="col-md-1 control-label">Codigo</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        {newEdit ? newcodigo : (<Input
                                            inputType={"text"}
                                            name={"newcodigo"}
                                            value={newcodigo}
                                            handleChange={this.handleChange}
                                            readonly={true}
                                        />)}
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Matricula</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newmatricula"}
                                            value={newmatricula}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Codigo Tipo Viatura</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newcodigo_tipo_viatura"}
                                            value={newcodigo_tipo_viatura}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Codigo Marca</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newcodigo_marca"}
                                            value={newcodigo_marca}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Modelo</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newmodelo"}
                                            value={newmodelo}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Codigo Tipo Combustivel</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newcodigo_tipo_combustivel"}
                                            value={newcodigo_tipo_combustivel}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Cilindrada</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newcilindrada"}
                                            value={newcilindrada}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Ano Matricula</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newano_matricula"}
                                            value={newano_matricula}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Chassis</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newchassis"}
                                            value={newchassis}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">PS</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newps"}
                                            value={newps}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Bloqueada</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Checkbox
                                            name={"newbloqueada"}
                                            checked={newbloqueada}
                                            handleChange={this.handleCheckboxChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Pergunta Kilometros</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Checkbox
                                            name={"newpergunta_kilometros"}
                                            checked={newpergunta_kilometros}
                                            handleChange={this.handleCheckboxChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Kms</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newkms"}
                                            value={newkms}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Pergunta Requisição</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Checkbox
                                            name={"newpergunta_requisicao"}
                                            checked={newpergunta_requisicao}
                                            handleChange={this.handleCheckboxChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Acumula Pontos</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Checkbox
                                            name={"newacumula_pontos"}
                                            checked={newacumula_pontos}
                                            handleChange={this.handleCheckboxChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Obs Consola Mat</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newobs_consola_mat"}
                                            value={newobs_consola_mat}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Sub Conta</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newsub_conta"}
                                            value={newsub_conta}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Colectivo</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newcolectivo"}
                                            value={newcolectivo}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-group">
                                <label className="col-md-1 control-label">Grupo Mat</label>  
                                <div className="col-md-11 inputGroupContainer">
                                    <div className="input-group">
                                        <span className="input-group-addon" style={{ backgroundColor: 'transparent' }}>&nbsp;</span>
                                        <Input
                                            inputType={"text"}
                                            name={"newgrupo_mat"}
                                            value={newgrupo_mat}
                                            handleChange={this.handleChange}
                                        />
                                    </div>
                                </div>
                            </div>
                            
                            <Button
                                action={this.handleMatriculasSubmitSuccess}
                                type={"success"}
                                title={"Submeter"}
                                style={buttonStyle}
                            />
                        </fieldset>
                    </div>
                </form>
            </Modal>
        </div>
      
    );
  }
}

const buttonStyle = {
  margin: "10px 10px 10px 10px"
};

export default withCookies(Form);
